import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import mime from 'mime-types';
import { autop } from '@wordpress/autop';
import isSameWeek from 'date-fns/isSameWeek';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import { useMediaQuery } from 'react-responsive';

import { WEBSITE_URL } from '@/app-configuration';
import PageContext from '@contexts/PageContext';
import { getLanguageId } from '@helpers/language';
import { PostBgHeroCheckup } from '@helpers/dataExists';
import { Desktop, Tablet } from '@/helpers/breakpoints';
import { Hero, Button } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';

import { smBreakpoint, mdBreakpoint, lgBreakpoint, xlBreakpoint } from '@styles/styles.module.scss';
import styles from './HeroSection.module.scss';

const HeroSection = ({ data, productType, pageId, isFormElements, componentIsAvailable, ...props }) => {
  const { Name, VisibleDate, DisplayOptions } = data;
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;

  const currentDate = new Date();
  const startDate = new Date(VisibleDate);

  const [backgrondHeroFetchedUrl, setBackgroundHeroFetchedUrl] = useState('');

  let isActive = true;

  if (DisplayOptions?.length === 0) {
    isActive = false;
  }

  if (Name === 'HeroWk1' || Name === 'HeroWk2') {
    isActive = isSameWeek(currentDate, startDate) || !(differenceInWeeks(currentDate, startDate) % 2);
  }

  // const isVideo = DisplayOptions?.find(
  //   (item) => item.DisplayOptionKey === 'BackgroundVideoURL',
  // )?.Enabled;

  // const backgroundVideoUrl = DisplayOptions?.find(
  //   (item) => item.DisplayOptionKey === 'BackgroundVideoURL',
  // )?.OptionInformation;

  const heroImgAlt = checkValue(DisplayOptions, 'ImageAccessibilityInfo', locale);

  //Check if Hero is gonna be shown on HomePage
  var homepage;
  var postpage;
  pageId = !pageId ? pageData.PageId : pageId;

  // PageId = 27 is Post - Individual page
  // PageId = 28  is Post - Group page
  switch (pageId) {
    case 1:
      homepage = true;
      break;

    case 27:
    case 28:
      homepage = false;
      postpage = true;
      break;

    default:
      homepage = false;
      postpage = false;
  }

  //Hero data if it is not on HomePage
  const postText = pageData.PostText.find((x) => parseInt(x.LanguageId) === getLanguageId(locale));

  //check background of Hero on postPage
  const backgroundPostPage = checkEnabled(DisplayOptions, 'Background');

  //check Hero Breadcrumb Enables
  const breadCrumbEnabled = checkEnabled(DisplayOptions, 'DisplayBreadCrumbs');

  //check postedBy
  const PostedByIsAvailable = checkEnabled(DisplayOptions, 'PostedBy');

  //postedby name
  const PostedByName = checkEnabled(DisplayOptions, 'PostedBy');

  //postedByText
  const PostedByText = checkValue(DisplayOptions, 'PostedByText', locale);

  //Hero date Enabled
  const heroDateIsAvailable = checkEnabled(DisplayOptions, 'VisibleDate');

  //Hero date
  const heroDate = DisplayOptions?.find((x) => x.DisplayOptionKey == 'VisibleDate')?.OptionInformation;

  //has BackgroundHero
  const backgroundHeroEnabled = checkEnabled(DisplayOptions, 'BackgroundHero');

  // BackgroundHeroVideo url
  const BackgroundHeroVideoUrl = DisplayOptions?.find((x) => x.DisplayOptionKey == 'BackgroundHeroVideo')?.OptionInformation;
  const BackgroundHeroVideoMobileUrl = DisplayOptions?.find((x) => x.DisplayOptionKey == 'BackgroundHeroVideoMobile')?.OptionInformation;

  //has BackgroundHeroVideo
  const heroVideoEnabled = checkEnabled(DisplayOptions, 'BackgroundHeroVideo');
  const heroVideoMobileEnabled = checkEnabled(DisplayOptions, 'BackgroundHeroVideoMobile') && BackgroundHeroVideoMobileUrl != "";

  //check ButtonLinkURL Enabled
  const ButtonLinkURLAvailable = checkEnabled(DisplayOptions, 'Link');

  //check ButtonTarget Enabled
  const ButtonTargetAvailable = checkEnabled(DisplayOptions, 'ButtonTarget');

  //check ButtonText Enabled
  const ButtonTextAvailable = checkEnabled(DisplayOptions, 'ButtonText');

  //check PostTextBody Enabled
  const PostTextBodyAvailable = checkEnabled(DisplayOptions, 'PostTextBody');

  //check Subject Enabled
  const SubjectAvailable = checkEnabled(DisplayOptions, 'Subject');

  //check ComponentTall
  const ComponentTall = checkEnabled(DisplayOptions, 'ComponentTall');

  let isMobile = useMediaQuery({ maxWidth: parseInt(smBreakpoint) });
  let isTablet = useMediaQuery({
    minWidth: parseInt(smBreakpoint) + 1,
    maxWidth: parseInt(lgBreakpoint) - 1
  });
  let isDesktop = useMediaQuery({ minWidth: lgBreakpoint });

  useEffect(async () => {
    if (backgroundHeroEnabled || (!backgroundHeroEnabled && heroVideoEnabled)) {
      try {
        const fetchedBgResult = await PostBgHeroCheckup(
          pageData.Id,
          homepage
            ? isDesktop
              ? `1920x650`
              : isTablet
              ? `1366x650`
              : isMobile
              ? `400x550`
              : null
            : isDesktop
            ? `1920x500`
            : isTablet
            ? `1366x500`
            : isMobile
            ? `640x360`
            : null
        );
        setBackgroundHeroFetchedUrl(fetchedBgResult);
      } catch (error) {
        console.error(`Error loading PostBgHeroCheckup image with ID: ${pageData?.Id}`, error?.message);
      }
    }
  }, [pageData?.Id]);

  const getType = () => {
    if (isTablet || isMobile) {
      if (backgroundHeroEnabled && heroVideoMobileEnabled) {
        return 'both';
      } else if (backgroundHeroEnabled && !heroVideoMobileEnabled) {
        return 'image';
      } else if (!backgroundHeroEnabled && heroVideoMobileEnabled) {
        return 'video';
      } else {
        return null;
      }
    } else {
      if (backgroundHeroEnabled && heroVideoEnabled) {
        return 'both';
      } else if (backgroundHeroEnabled && !heroVideoEnabled) {
        return 'image';
      } else if (!backgroundHeroEnabled && heroVideoEnabled) {
        return 'video';
      } else {
        return null;
      }
    }
  };

  const getBackground = () => {
    if (isTablet || isMobile) {
      if (backgroundHeroEnabled && heroVideoMobileEnabled) {
        return {
          imageSource: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'both',
            width: isTablet ? '1366' : isMobile ? '400' : null,
            isHero: true
          },
          videoSource: {
            source: [
              {
                src: BackgroundHeroVideoMobileUrl,
                type: 'both'
              }
            ]
          }
        };
      } else if (backgroundHeroEnabled && !heroVideoMobileEnabled) {
        return {
          source: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'image',
            width: isTablet ? '1366' : isMobile ? '400' : null,
            isHero: true
          }
        };
      } else if (!backgroundHeroEnabled && heroVideoMobileEnabled) {
        return {
          source: [
            {
              src: BackgroundHeroVideoMobileUrl,
              type: 'video'
            }
          ]
        };
      } else {
        return null;
      }
    } else {
      if (backgroundHeroEnabled && heroVideoEnabled) {
        return {
          imageSource: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'both',
            width: isDesktop ? '1920' : null,
            isHero: true
          },
          videoSource: {
            source: [
              {
                src: BackgroundHeroVideoUrl,
                type: 'both'
              }
            ]
          }
        };
      } else if (backgroundHeroEnabled && !heroVideoEnabled) {
        return {
          source: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'image',
            width: isDesktop ? '1920' : null,
            isHero: true
          }
        };
      } else if (!backgroundHeroEnabled && heroVideoEnabled) {
        return {
          source: [
            {
              src: BackgroundHeroVideoUrl,
              type: 'video'
            }
          ]
        };
      } else {
        return null;
      }
    }
  };

  const PostPageBreadcrumb = pageData.BreadCrumb ? pageData.BreadCrumb : [{ Title: postText.Subject }];

  return isActive && componentIsAvailable ? (
    <>
      <div className={(backgroundPostPage || homepage) ? styles.HeroOuterContainer : styles.FullWidth}>
        <Hero
          componentTall={ComponentTall}
          isFormElements={isFormElements}
          pageType={homepage ? 'HomePage' : postpage ? 'PostPage' : null}
          hasShadow={homepage || postpage ? true : false}
          shadowType={homepage || postpage ? 'DefaultShadow' : null}
          // backgroundHeroEnabled={backgroundHeroEnabled}
          homepage={homepage}
          postpage={postpage}
          breadcrumbs={!homepage && breadCrumbEnabled ? PostPageBreadcrumb : null}
          type={getType()}
          background={backgroundHeroEnabled || heroVideoEnabled ? getBackground() : null}
          {...props}
        >
          {SubjectAvailable && <Hero.Title>{postText.Subject}</Hero.Title>}

          {heroDateIsAvailable ? <Hero.HDate>{heroDate}</Hero.HDate> : null}

          {PostedByIsAvailable ? (
            <Hero.PostedBy>
              {PostedByText} <b>{PostedByName}</b>
            </Hero.PostedBy>
          ) : null}

          <Hero.Text>
            {(isDesktop || isTablet) && PostTextBodyAvailable && (
              <div className={styles.Description}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: autop(postText.PostTextBody)
                  }}
                ></div>
              </div>
            )}

            {ButtonTextAvailable && (
              <Button
                animated
                borderRadius
                href={ButtonLinkURLAvailable ? postText.Link : null}
                target={ButtonTargetAvailable ? postText.ButtonTarget : null}
                className={styles.Button}
              >
                {postText.ButtonText}
              </Button>
            )}
          </Hero.Text>
        </Hero>
      </div>
      {isMobile && (
        <div>
          <div className={styles.MobileDescription}>
            <div
              dangerouslySetInnerHTML={{
                __html: autop(postText.PostTextBody)
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default HeroSection;
