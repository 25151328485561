import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import SwiperCore, { Mousewheel, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';

import { getLanguageId } from '@helpers/language';
import { Mobile } from '@helpers/breakpoints';
import { Container } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import { apiClient } from '@/http-client';

import { smBreakpoint } from '@styles/styles.module.scss';
import styles from './ApplicationsBar.module.scss';

import ArrowIcon from '@assets/icon/arrow-left.svg';
import CloseIcon from '@assets/icon/close.svg';

SwiperCore.use([Mousewheel, Scrollbar]);

const ApplicationsBar = ({ data, componentIsAvailable }) => {
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const { DisplayOptions } = data;

  //application List
  const applicationListURL = DisplayOptions.find((item) => item.DisplayOptionKey === 'ApplicationListArray').OptionInformation;

  const getApplicationList = async () => {
    try {
      const { data: res } = await apiClient.get(`${applicationListURL}`);

      let applicationItems = res?.Children?.map((item) => item.p).map((x) =>
        x.PostText.find(({ LanguageId }) => parseInt(LanguageId) === getLanguageId(locale))
      );

      setItems(applicationItems);
    } catch (error) {
      console.error('Fetching application list failed:', applicationListURL);
    }
  };

  useEffect(() => {
    if (applicationListAvailable) {
      getApplicationList();
    }
  }, []);

  useEffect(() => {
    if (!items || items.length === 0) return;

    setActiveItem(items.length / 2);
  }, [items]);

  const isMobile = useMediaQuery({ maxWidth: parseInt(smBreakpoint) - 1 });
  const itemHeight = parseInt(isMobile ? styles.mobileItemHeight : styles.desktopItemHeight);
  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  //check SectionText Enabled
  const SectionTextAvailable = checkEnabled(DisplayOptions, 'SectionText');
  //text
  const sectionText = checkValue(DisplayOptions, 'SectionText', locale);

  //check ApplicationListArray Enabled
  const applicationListAvailable = checkEnabled(DisplayOptions, 'ApplicationListArray');

  return componentIsAvailable ? (
    <div className={styles.ApplicationsBar}>
      <Container className={styles.Container}>
        {SectionTextAvailable && <p className={styles.Prompt}>{sectionText}</p>}
        {applicationListAvailable && (
          <div className={styles.MenuContainer}>
            <div className={styles.MenuWrapper}>
              <motion.ul
                className={styles.ApplicationsMenu}
                initial={{ y: '-100%', opacity: 0 }}
                animate={{ y: -(activeItem - 1) * itemHeight, opacity: 1 }}
                transition={{ ease: [0.165, 0.84, 0.44, 1], duration: 3 }}
                onClick={handleClick}
              >
                {items?.map((application, index) => (
                  <li
                    className={classNames(styles.ApplicationsMenuItem, {
                      [styles.Active]: index + 1 === activeItem
                    })}
                    key={index}
                  >
                    {application.Subject}
                    {index + 1 === activeItem && <ArrowIcon className={styles.SelectArrow} />}
                  </li>
                ))}
              </motion.ul>
            </div>
            <AnimatePresence>
              {menuOpen && (
                <motion.div
                  className={styles.MenuSlideout}
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  transition={{
                    ease: [0.165, 0.84, 0.44, 1],
                    duration: 1,
                    when: 'beforeChildren'
                  }}
                >
                  <div className={styles.SlideoutContainer}>
                    <Mobile>
                      <Container className={styles.SlideoutText}>
                        <button className={styles.SlideoutClose} onClick={() => setMenuOpen(false)}>
                          <CloseIcon className={styles.SlideoutCloseIcon} />
                        </button>
                        <p>{sectionText}</p>
                        <p>
                          <strong>Tap to browser by application:</strong>
                        </p>
                      </Container>
                    </Mobile>
                    <Swiper
                      className={styles.SlideoutSwiper}
                      direction="vertical"
                      slidesPerView="auto"
                      freeMode
                      scrollbar
                      mousewheel
                    >
                      <SwiperSlide className={styles.SlideoutSlide}>
                        <motion.ul
                          className={styles.SlideoutList}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            ease: [0.165, 0.84, 0.44, 1],
                            duration: 0.3
                          }}
                        >
                          {items?.map((application, indx) => (
                            <li key={indx} className={styles.SlideoutItem}>
                              <Link href={`/posts/${application.PostId}`} passHref prefetch={false}>
                                <a className={styles.SlideoutLink}>
                                  {application.Subject}
                                  <ArrowIcon className={styles.SlideoutArrow} />
                                </a>
                              </Link>
                            </li>
                          ))}
                        </motion.ul>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </Container>
    </div>
  ) : null;
};

export default ApplicationsBar;
